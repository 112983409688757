import Cookies from "js-cookie";
const adminStates = {
  // Admin Leads
  s_adminLeadsLoadingStatus: null,
  s_adminLeadsData: [],
  // Admin Leads Edit
  s_adminLeadsEditStatus: null,
};

const adminGetters = {
  // Admin Leads
  g_adminLeadsLoadingStaus: (state) => state.s_adminLeadsLoadingStatus,
  g_adminLeadsData: (state) => state.s_adminLeadsData,
  // Admin Leads Edit Status
  g_adminLeadsEditStatus: (state) => state.s_adminLeadsEditStatus,
};

const adminActions = {
  // Admin Leads Load
  async a_AdminLeadsLoad(context, payload) {
    context.commit("admin_leads");
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        context: {
          ...this._vm.$context,
        },
        model: "education_hub.pre_lead",
        domain: [],
        fields: [],
        sort: "",
        limit: 0,
      },
    };

    await this._vm.$http
      .post("/web/dataset/search_read", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log("data", "leads :::: ", res);
        const { result = null } = res.data;
        if (result) {
          context.commit("admin_leads_success", result);
        } else {
          context.commit("admin_leads_error");
        }
      })
      .catch((err) => {
        context.commit("admin_leads_error");
        return err;
      });
  },
  // Admin Leads Edit Load
  async a_AdminLeadsEdit(context, payload) {
    context.commit("admin_leads_edit");

    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        kwargs: {
          context: {
            ...this._vm.$context,
          },
        },
        args: [
          [payload.id],
          {
            ...payload.fields,
          },
        ],
        method: "write",
        model: "education_hub.pre_lead",
      },
    };

    await this._vm.$http
      .post("/web/dataset/call_kw/write", req_data)
      .then((res) => {
        const { result = null } = res.data;
        if (result) {
          context.commit("admin_leads_edit_success", result);
        } else {
          context.commit("admin_leads_edit_error");
        }
      })
      .catch((err) => {
        context.commit("admin_leads_edit_error");
        return err;
      });
  },
};

const adminMutators = {
  // Admin Daily Leads
  admin_leads(state) {
    state.s_adminLeadsLoadingStatus = null;
    state.s_adminLeadsData = [];
  },
  admin_leads_success(state, payload) {
    state.s_adminLeadsLoadingStatus = "success";
    state.s_adminLeadsData = payload;
  },
  admin_leads_error(state) {
    state.s_adminLeadsLoadingStatus = "error";
    state.s_adminLeadsData = [];
  },
  // Admin Leads Edit
  admin_leads_edit(state) {
    state.s_adminLeadsEditStatus = null;
  },
  admin_leads_edit_success(state, payload) {
    state.s_adminLeadsEditStatus = "success";
  },
  admin_leads_edit_error(state) {
    state.s_adminLeadsEditStatus = "error";
  },
};

export { adminStates, adminGetters, adminActions, adminMutators };
