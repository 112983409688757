const menuStates = {
  s_tabMenuNavs: [],
  s_tabMenuLoading: false,
};

const menuGetters = {
  g_tabMenuNavs: (state) => state.s_tabMenuNavs,
  g_tabMenuLoading: (state) => state.s_tabMenuLoading,
};
//a

const menuActions = {
  a_configureTabMenu({ commit, state }, tab) {
    commit("menu_cfg_tab_init");
    var sideNavs = [];
    console.log("tabs", tab);
    switch (tab) {
      case "callcenter":
        sideNavs = [
          {
            _name: "CSidebarNavItem",
            name: "CallCenter",
            to: "/dashboard/call-center",
            icon: "cil-meh",
            // badge: {
            //   color: "primary",
            //   text: "NEW",
            // },
          },
        ];
        break;
      case "admin":
        sideNavs = [
          {
            _name: "CSidebarNavItem",
            name: "Admin",
            to: "/dashboard/admin",
            icon: "cil-user",
          },
        ];
        break;

      case "insights":
        sideNavs = [
          {
            _name: "CSidebarNavItem",
            name: "Insights",
            to: "/dashboard/insights",
            icon: "cil-lightbulb",
          },
        ];
        break;

      default:
        break;
    }
    commit("menu_cfg_tab_success", sideNavs);
  },
};

const menuMutates = {
  menu_cfg_tab_init(state) {
    state.s_tabMenuLoading = true;
  },
  menu_cfg_tab_success(state, payload) {
    // console.log(payload);
    state.s_tabMenuNavs = [...payload];
  },
};

export { menuStates, menuMutates, menuActions, menuGetters };
