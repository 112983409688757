import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import Toasted from "vue-toasted";
import axios from "axios";
import Settings from "./config/settings";
import underscore from "vue-underscore";
require("babel-polyfill");

import "../node_modules/material-icons/iconfont/material-icons.scss";

Vue.use(HighchartsVue);
Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(underscore);
Vue.prototype.$log = console.log.bind(console);

// axios.defaults.baseURL = Settings.baseURL;
// axios.defaults.withCredentials = true;
// axios.defaults.cros = true;
Vue.prototype.$http = axios.create({
  baseURL: Settings.baseURL,
  withCredentials: true,
  crossDomain: true,
});

/*******************************
 * User Context
 * ************************** */
Vue.prototype.$context = localStorage.getItem("user_context")
  ? JSON.parse(localStorage.getItem("user_context"))
  : {};
/*******************************
 * Vue Toasted
 * ************************** */
let toaster = Vue.use(Toasted, {
  iconPack: "material",
});
Vue.prototype.$errorToast = function(message, duration = 5000) {
  Vue.toasted.show(message, {
    type: "error",
    position: "bottom-center",
    duration,
    icon: "info",
  });
};

Vue.prototype.$successToast = function(message, duration = 5000) {
  Vue.toasted.show(message, {
    type: "success",
    position: "bottom-center",
    duration,
    icon: "task_alt",
  });
};

var vue = new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
});

export default vue;
