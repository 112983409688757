import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import { menuStates, menuActions, menuMutates, menuGetters } from "./menu";
import { authStates, authGetters, authActions, authMutators } from "./auth";
import {
  insightStates,
  insightGetters,
  insightActions,
  insightMutators,
} from "./insights";
import {
  adminStates,
  adminGetters,
  adminActions,
  adminMutators,
} from "./admin";
import {
  callcenterStates,
  callcenterGetters,
  callcenterActions,
  callcenterMutators,
} from "./callcenter";

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  ...authStates,
  ...menuStates,
  ...insightStates,
  ...adminStates,
  ...callcenterStates,
};

const getters = {
  ...authGetters,
  ...menuGetters,
  ...insightGetters,
  ...adminGetters,
  ...callcenterGetters,
};
const actions = {
  ...authActions,
  ...menuActions,
  ...insightActions,
  ...adminActions,
  ...callcenterActions,
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    console.log("VUEX-", this._vm);
    state[variable] = value;
  },
  ...authMutators,
  ...menuMutates,
  ...insightMutators,
  ...adminMutators,
  ...callcenterMutators,
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
