import Cookies from "js-cookie";
const insightStates = {
  // Daily Leads
  s_dailyLeadsStaus: null,
  s_dailyLeadsData: [],
  // Weekly Leads
  s_weeklyLeadsStaus: null,
  s_weeklyLeadsData: [],
  // Monthly Leads
  s_monthlyLeadsStaus: null,
  s_monthlyLeadsData: [],
  // Call Status Leads
  s_callStatusLeadsStaus: null,
  s_callStatusLeadsData: [],
  // Facebook Social Leads
  s_facebookLeadsStaus: null,
  s_facebookLeadsData: [],
  // Linkedin Social Leads
  s_linkedinLeadsStaus: null,
  s_linkedinLeadsData: [],
  // Google Ads Leads
  s_googleAdsLeadsStaus: null,
  s_googleAdsLeadsData: [],
  // Dev360 Leads
  s_dev360LeadsStaus: null,
  s_dev360LeadsData: [],
  // Lost Reason Number
  s_lostReasonNoLoadingStaus: null,
  s_lostReasonNoData: [],
  // Datatable
  s_datatableLoadingStaus: null,
  s_datatableData: [],
};

const insightGetters = {
  // Daily Leads
  g_dailyLeadsStaus: (state) => state.s_dailyLeadsStaus,
  g_dailyLeadsData: (state) => state.s_dailyLeadsData,
  // Weekly Leads
  g_weeklyLeadsStaus: (state) => state.s_weeklyLeadsStaus,
  g_weeklyLeadsData: (state) => state.s_weeklyLeadsData,
  // Monthly Leads
  g_monthlyLeadsStaus: (state) => state.s_monthlyLeadsStaus,
  g_monthlyLeadsData: (state) => state.s_monthlyLeadsData,
  // Call Status Leads
  g_callStatusLeadsStaus: (state) => state.s_callStatusLeadsStaus,
  g_callStatusLeadsData: (state) => state.s_callStatusLeadsData,
  // Facebook Leads
  g_facebookLeadsStaus: (state) => state.s_facebookLeadsStaus,
  g_facebookLeadsData: (state) => state.s_facebookLeadsData,
  // Linked In Leads
  g_linkedinLeadsStaus: (state) => state.s_linkedinLeadsStaus,
  g_linkedinLeadsData: (state) => state.s_linkedinLeadsData,
  // Google Ads Leads
  g_googleAdsLeadsStaus: (state) => state.s_googleAdsLeadsStaus,
  g_googleAdsLeadsData: (state) => state.s_googleAdsLeadsData,
  // Dev360 Leads
  g_dev360LeadsStaus: (state) => state.s_dev360LeadsStaus,
  g_dev360LeadsData: (state) => state.s_dev360LeadsData,
  // Lost Reason No
  g_lostReasonNoLoadingStaus: (state) => state.s_lostReasonNoLoadingStaus,
  g_lostReasonNoData: (state) => state.s_lostReasonNoData,
  // Datatable
  g_datatableLoadingStaus: (state) => state.s_datatableLoadingStaus,
  g_datatableData: (state) => state.s_datatableData,
};

const insightActions = {
  // Daily Leads Load
  async a_DailyLeadsLoad(context, payload) {
    context.commit("daily_leads");
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        kwargs: {
          context: {
            ...this._vm.$context,
          },
          domain: [["apto", "!=", false]],
          fields: [],
          groupby: ["create_date:day", "apto"],
          lazy: false,
          orderby: "",
        },
        method: "read_group",
        model: "education_hub.pre_lead",
        args: [],
      },
    };
    await this._vm.$http
      .post("/web/dataset/call_kw/read_group", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log("data", "leads :::: ", res);
        const { result = null } = res.data;
        if (result) {
          context.commit("daily_leads_success", result);
        } else {
          context.commit("daily_leads_error");
        }
      })
      .catch((err) => {
        context.commit("daily_leads_error");
        return err;
      });
  },
  // Weekly Leads Load
  async a_WeeklyLeadsLoad(context, payload) {
    context.commit("weekly_leads");
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        kwargs: {
          context: {
            ...this._vm.$context,
          },
          domain: [["apto", "!=", false]],
          fields: [],
          groupby: ["create_date:week", "apto"],
          lazy: false,
          orderby: "",
        },
        method: "read_group",
        model: "education_hub.pre_lead",
        args: [],
      },
    };
    await this._vm.$http
      .post("/web/dataset/call_kw/read_group", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log("data", "leads :::: ", res);
        const { result = null } = res.data;
        if (result) {
          context.commit("weekly_leads_success", result);
        } else {
          context.commit("weekly_leads_error");
        }
      })
      .catch((err) => {
        context.commit("weekly_leads_error");
        return err;
      });
  },
  // Monthly Leads Load
  async a_MonthlyLeadsLoad(context, payload) {
    context.commit("monthly_leads");
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        kwargs: {
          context: {
            ...this._vm.$context,
          },
          domain: [["apto", "!=", false]],
          fields: [],
          groupby: ["create_date:month", "apto"],
          lazy: false,
          orderby: "",
        },
        method: "read_group",
        model: "education_hub.pre_lead",
        args: [],
      },
    };
    await this._vm.$http
      .post("/web/dataset/call_kw/read_group", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log("data", "leads :::: ", res);
        const { result = null } = res.data;
        if (result) {
          context.commit("monthly_leads_success", result);
        } else {
          context.commit("monthly_leads_error");
        }
      })
      .catch((err) => {
        context.commit("monthly_leads_error");
        return err;
      });
  },
  // Call Status Leads Load
  async a_CallStatusLeadsLoad(context, payload) {
    context.commit("call_status_leads");
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        kwargs: {
          context: {
            ...this._vm.$context,
          },
          domain: [["final_status", "!=", false]],
          fields: [],
          groupby: ["final_status"],

          lazy: false,
          orderby: "",
        },
        method: "read_group",
        model: "education_hub.pre_lead",
        args: [],
      },
    };

    await this._vm.$http
      .post("/web/dataset/call_kw/read_group", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log("data", "leads :::: ", res);
        const { result = null } = res.data;
        if (result) {
          context.commit("call_status_leads_success", result);
        } else {
          context.commit("call_status_leads_error");
        }
      })
      .catch((err) => {
        context.commit("call_status_leads_error");
        return err;
      });
  },
  // Facebook Leads Load
  async a_FacebookLeadsLoad(context, payload) {
    context.commit("facebook_leads");
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        kwargs: {
          context: {
            ...this._vm.$context,
          },
          domain: [
            "|",
            ["origin", "like", "Facebook"],
            "&",
            ["origin", "like", "Unbounce"],
            ["source", "=", "facebook"],
            ["apto", "!=", false],
          ],
          fields: ["origin", "apto"],
          groupby: ["apto"],
          lazy: false,
          orderby: "",
        },
        method: "read_group",
        model: "education_hub.pre_lead",
        args: [],
      },
    };

    await this._vm.$http
      .post("/web/dataset/call_kw/read_group", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log("data", "leads :::: ", res);
        const { result = null } = res.data;
        if (result) {
          context.commit("facebook_leads_success", result);
        } else {
          context.commit("facebook_leads_error");
        }
      })
      .catch((err) => {
        context.commit("facebook_leads_error");
        return err;
      });
  },
  // Linked In Leads Load
  async a_LinkedInLeadsLoad(context, payload) {
    context.commit("linkedin_leads");
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        kwargs: {
          context: {
            ...this._vm.$context,
          },
          domain: [
            "|",
            ["origin", "like", "linkedin"],
            "&",
            ["origin", "like", "Unbounce"],
            ["source", "=", "linkedin"],
            ["apto", "!=", false],
          ],
          fields: ["origin", "apto"],
          groupby: ["apto"],
          lazy: false,
          orderby: "",
        },
        method: "read_group",
        model: "education_hub.pre_lead",
        args: [],
      },
    };

    await this._vm.$http
      .post("/web/dataset/call_kw/read_group", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log("data", "leads :::: ", res);
        const { result = null } = res.data;
        if (result) {
          context.commit("linkedin_leads_success", result);
        } else {
          context.commit("linkedin_leads_error");
        }
      })
      .catch((err) => {
        context.commit("linkedin_leads_error");
        return err;
      });
  },
  // Google Ads Leads Load
  async a_GoogleAdsLeadsLoad(context, payload) {
    context.commit("googleads_leads");
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        kwargs: {
          context: {
            ...this._vm.$context,
          },
          domain: [
            "|",
            ["origin", "like", "Google"],
            "&",
            "&",
            ["origin", "like", "Unbounce"],
            ["source", "=", "google"],
            ["medium", "=", "cpc"],
            ["apto", "!=", false],
          ],
          fields: ["origin", "apto"],
          groupby: ["apto"],
          lazy: false,
          orderby: "",
        },
        method: "read_group",
        model: "education_hub.pre_lead",
        args: [],
      },
    };

    await this._vm.$http
      .post("/web/dataset/call_kw/read_group", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log("data", "leads :::: ", res);
        const { result = null } = res.data;
        if (result) {
          context.commit("googleads_leads_success", result);
        } else {
          context.commit("googleads_leads_error");
        }
      })
      .catch((err) => {
        context.commit("googleads_leads_error");
        return err;
      });
  },
  // Dev360 Leads Load
  async a_Dev360LeadsLoad(context, payload) {
    context.commit("dev360_leads");
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        kwargs: {
          context: {
            ...this._vm.$context,
          },
          domain: [
            "&",
            "&",
            ["origin", "like", "Unbounce"],
            ["source", "=", "google"],
            ["medium", "=", "display"],
            ["apto", "!=", false],
          ],
          fields: ["origin", "apto"],
          groupby: ["apto"],
          lazy: false,
          orderby: "",
        },
        method: "read_group",
        model: "education_hub.pre_lead",
        args: [],
      },
    };
    await this._vm.$http
      .post("/web/dataset/call_kw/read_group", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log("data", "leads :::: ", res);
        const { result = null } = res.data;
        if (result) {
          context.commit("dev360_leads_success", result);
        } else {
          context.commit("dev360_leads_error");
        }
      })
      .catch((err) => {
        context.commit("dev360_leads_error");
        return err;
      });
  },
  // Lost Reason No
  async a_LostReasonNoLoad(context, payload) {
    context.commit("lostreason_no");
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        context: {
          uid: 2,
          lang: "es_ES",
        },
        model: "education_hub.pre_lead",
        domain: [
          "|",
          "|",
          "|",
          "|",
          ["presential", "=", "2"],
          ["schedule", "=", "2"],
          ["languaje", "=", "2"],
          ["practices", "=", "2"],
          ["title", "=", "2"],
          ["presential", "!=", false],
          ["schedule", "!=", false],
          ["languaje", "!=", false],
          ["practices", "!=", false],
          ["title", "!=", false],
        ],
        fields: ["presential", "schedule", "languaje", "practices", "title"],
        sort: "",
        limit: 0,
      },
    };
    await this._vm.$http
      .post("/web/dataset/search_read", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log("data", "leads :::: ", res);
        const { result = null } = res.data;
        if (result) {
          context.commit("lostreason_no_success", result);
        } else {
          context.commit("lostreason_no_error");
        }
      })
      .catch((err) => {
        context.commit("lostreason_no_error");
        return err;
      });
  },
  // Datatable load
  async a_DatatableLoad(context, payload) {
    context.commit("datatable");
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        context: {
          ...this._vm.$context,
        },
        model: "education_hub.pre_lead",
        domain: [],
        fields: [
          "degree",
          "call_done_date",
          "email1_date",
          "email2_date",
          "email3_date",
        ],
        sort: "",
        limit: 0,
      },
    };
    await this._vm.$http
      .post("/web/dataset/search_read", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log("data", "leads :::: ", res);
        const { result = null } = res.data;
        if (result) {
          context.commit("datatable_success", result);
        } else {
          context.commit("datatable_error");
        }
      })
      .catch((err) => {
        context.commit("datatable_error");
        return err;
      });
  },
};

const insightMutators = {
  // Daily Leads
  daily_leads(state) {
    state.s_dailyLeadsStaus = null;
    state.s_dailyLeadsData = [];
  },
  daily_leads_success(state, payload) {
    state.s_dailyLeadsStaus = "success";
    state.s_dailyLeadsData = payload;
  },
  daily_leads_error(state) {
    state.s_dailyLeadsStaus = "error";
    state.s_dailyLeadsData = [];
  },
  // Weekly Leads
  weekly_leads(state) {
    state.s_weeklyLeadsStaus = null;
    state.s_weeklyLeadsData = [];
  },
  weekly_leads_success(state, payload) {
    state.s_weeklyLeadsStaus = "success";
    state.s_weeklyLeadsData = payload;
  },
  weekly_leads_error(state) {
    state.s_weeklyLeadsStaus = "error";
    state.s_weeklyLeadsData = [];
  },
  // Monthly Leads
  monthly_leads(state) {
    state.s_monthlyLeadsStaus = null;
    state.s_monthlyLeadsData = [];
  },
  monthly_leads_success(state, payload) {
    state.s_monthlyLeadsStaus = "success";
    state.s_monthlyLeadsData = payload;
  },
  monthly_leads_error(state) {
    state.s_monthlyLeadsStaus = "error";
    state.s_monthlyLeadsData = [];
  },
  // Call Status Leads
  call_status_leads(state) {
    state.s_callStatusLeadsStaus = null;
    state.s_callStatusLeadsData = [];
  },
  call_status_leads_success(state, payload) {
    state.s_callStatusLeadsStaus = "success";
    state.s_callStatusLeadsData = payload;
  },
  call_status_leads_error(state) {
    state.s_callStatusLeadsStaus = "error";
    state.s_callStatusLeadsData = [];
  },
  // Facebook Leads
  facebook_leads(state) {
    state.s_facebookLeadsStaus = null;
    state.s_facebookLeadsData = [];
  },
  facebook_leads_success(state, payload) {
    state.s_facebookLeadsStaus = "success";
    state.s_facebookLeadsData = payload;
  },
  facebook_leads_error(state) {
    state.s_facebookLeadsStaus = "error";
    state.s_facebookLeadsData = [];
  },
  // Linked In Leads
  linkedin_leads(state) {
    state.s_linkedinLeadsStaus = null;
    state.s_linkedinLeadsData = [];
  },
  linkedin_leads_success(state, payload) {
    state.s_linkedinLeadsStaus = "success";
    state.s_linkedinLeadsData = payload;
  },
  linkedin_leads_error(state) {
    state.s_linkedinLeadsStaus = "error";
    state.s_linkedinLeadsData = [];
  },
  // Google Ads Leads
  googleads_leads(state) {
    state.s_googleAdsLeadsStaus = null;
    state.s_googleAdsLeadsData = [];
  },
  googleads_leads_success(state, payload) {
    state.s_googleAdsLeadsStaus = "success";
    state.s_googleAdsLeadsData = payload;
  },
  googleads_leads_error(state) {
    state.s_googleAdsLeadsStaus = "error";
    state.s_googleAdsLeadsData = [];
  },
  // Dev360 Leads
  dev360_leads(state) {
    state.s_dev360LeadsStaus = null;
    state.s_dev360LeadsData = [];
  },
  dev360_leads_success(state, payload) {
    state.s_dev360LeadsStaus = "success";
    state.s_dev360LeadsData = payload;
  },
  dev360_leads_error(state) {
    state.s_dev360LeadsStaus = "error";
    state.s_dev360LeadsData = [];
  },
  // Lost Reason No
  lostreason_no(state) {
    state.s_lostReasonNoStaus = null;
    state.s_lostReasonNoData = [];
  },
  lostreason_no_success(state, payload) {
    state.s_lostReasonNoStaus = "success";
    state.s_lostReasonNoData = payload;
  },
  lostreason_no_error(state) {
    state.s_lostReasonNoStaus = "error";
    state.s_lostReasonNoData = [];
  },
  // Datatable Loads
  datatable(state) {
    state.s_datatableStaus = null;
    state.s_datatableData = [];
  },
  datatable_success(state, payload) {
    state.s_datatableStaus = "success";
    state.s_datatableData = payload;
  },
  datatable_error(state) {
    state.s_datatableStaus = "error";
    state.s_datatableData = [];
  },
};

export { insightStates, insightGetters, insightActions, insightMutators };
