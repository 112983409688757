const callcenterStates = {
  // callcenter Leads
  s_callcenterLeadsLoadingStatus: null,
  s_callcenterLeadsData: [],
  // callcenter Leads Edit
  s_callcenterLeadsEditStatus: null,
  // leads single
  s_leadsSingleLoading: null,
  s_leadsSingleData: [],
};

const callcenterGetters = {
  // callcenter Leads
  g_callcenterLeadsLoadingStaus: (state) =>
    state.s_callcenterLeadsLoadingStatus,
  g_callcenterLeadsData: (state) => state.s_callcenterLeadsData,
  // callcenter Leads Edit Status
  g_callcenterLeadsEditStatus: (state) => state.s_callcenterLeadsEditStatus,
  // Callcenter leads single
  g_leadsSingleLoading: (state) => state.s_leadsSingleLoading,
  g_leadsSingleData: (state) => state.s_leadsSingleData,
};

const callcenterActions = {
  // callcenter Leads Load
  async a_callcenterLeadsLoad(context, payload) {
    context.commit("callcenter_leads");
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        context: {
          ...this._vm.$context,
        },
        model: "education_hub.pre_lead",
        domain: [
          ["apto", "=", false],
          ["stage_id.stage_name", "=", "Pre lead"],
        ],
        fields: ["degree", "user_name", "phone", "email"],
        sort: "",
        limit: 0,
      },
    };

    await this._vm.$http
      .post("/web/dataset/search_read", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log("data", "leads :::: ", res);
        const { result = null } = res.data;
        if (result) {
          context.commit("callcenter_leads_success", result);
        } else {
          context.commit("callcenter_leads_error");
        }
      })
      .catch((err) => {
        context.commit("callcenter_leads_error");
        return err;
      });
  },
  // callcenter Leads Edit Load
  async a_callcenterLeadsEdit(context, payload) {
    context.commit("callcenter_leads_edit");

    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        kwargs: {
          context: {
            ...this._vm.$context,
          },
        },
        args: [
          [payload.id],
          {
            ...payload.fields,
          },
        ],
        method: "write",
        model: "education_hub.pre_lead",
      },
    };

    await this._vm.$http
      .post("/web/dataset/call_kw/write", req_data)
      .then((res) => {
        const { result = null } = res.data;
        if (result) {
          context.commit("callcenter_leads_edit_success", result);
        } else {
          context.commit("callcenter_leads_edit_error");
        }
      })
      .catch((err) => {
        context.commit("callcenter_leads_edit_error");
        return err;
      });
  },
  // Leads Single Load
  async a_leadsSingleLoad(context, payload) {
    context.commit("leads_single");
    const { id, fields } = payload;
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        kwargs: { context: { ...this._vm.$context } },
        model: "education_hub.pre_lead",
        args: [[id], [
          ...fields
        ]],
        method: "read",
      },
    };
    await this._vm.$http
      .post("/web/dataset/call_kw/read", req_data)
      .then((res) => {
        const { result = null } = res.data;
        if (result) {
          context.commit("leads_single_success", result);
        } else {
          context.commit("leads_single_error");
        }
      })
      .catch((err) => {
        context.commit("leads_single_error");
        return err;
      });
  },
};

const callcenterMutators = {
  // callcenter Daily Leads
  callcenter_leads(state) {
    state.s_callcenterLeadsLoadingStatus = null;
    state.s_callcenterLeadsData = [];
  },
  callcenter_leads_success(state, payload) {
    state.s_callcenterLeadsLoadingStatus = "success";
    state.s_callcenterLeadsData = payload;
  },
  callcenter_leads_error(state) {
    state.s_callcenterLeadsLoadingStatus = "error";
    state.s_callcenterLeadsData = [];
  },
  // callcenter Leads Edit
  callcenter_leads_edit(state) {
    state.s_callcenterLeadsEditStatus = null;
  },
  callcenter_leads_edit_success(state, payload) {
    state.s_callcenterLeadsEditStatus = "success";
  },
  callcenter_leads_edit_error(state) {
    state.s_callcenterLeadsEditStatus = "error";
  },
  // Leads Single
  leads_single(state) {
    state.s_leadsSingleLoading = null;
  },
  leads_single_success(state, payload) {
    state.s_leadsSingleLoading = "success";
    state.s_leadsSingleData = payload[0]
  },
  leads_single_error(state) {
    state.s_leadsSingleLoading = "error";
  },
};

export {
  callcenterStates,
  callcenterGetters,
  callcenterActions,
  callcenterMutators,
};
