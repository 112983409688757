import Cookies from "js-cookie";
const authStates = {
  s_role: null,
  s_loggedIn: null,
  s_status_auth: null,
  // Login Credentials
  s_user: {},
  s_authpayload: {},
  // Get Session Info
  s_sessionStatus: null,
  s_sessionInfo: {},
  // User Group
  s_userGroupLoadingStatus: null,
  s_userGroup: null,
};

const authGetters = {
  g_role: (state) =>
    state.s_role
      ? state.s_role
      : Cookies.get("edu_role")
      ? Cookies.get("edu_role")
      : null,
  // Logged In
  g_isLoggedIn: (state) =>
    state.s_loggedIn
      ? state.s_loggedIn
      : Cookies.get("edu_islogged")
      ? true
      : false,

  g_status_auth: (state) => state.s_status_auth,
  g_user: (state) => state.s_user,
  g_authpayload: (state) => state.s_authpayload,

  // Session Info
  g_sessionInfo: (state) => state.s_sessionInfo,
  g_sessionStatus: (state) => state.s_sessionStatus,

  // User Group
  g_userGroupLoadingStatus: (state) => state.s_userGroupLoadingStatus,
  g_userGroup: (state) => state.s_userGroup,
};

const authActions = {
  a_SetRole({ commit, state }, role) {
    commit("set_role", null);
    commit("set_role_success", role);
  },
  // Logged In
  a_LoggedIn({ commit, state }, data) {
    commit("logged_in", data);
  },

  async a_login(context, payload) {
    context.commit("auth_request");
    var req_data = {
      jsonrpc: "2.0",
      params: {
        db: "icai-prod2",
        login: payload.user,
        password: payload.password,
      },
    };
    var req_data11 = {
      jsonrpc: "2.0",
      params: {},
    };

    await this._vm.$http
      .post("/web/session/authenticate", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        console.log("res dat1111 :::: ", res);
        if (res.status === 200 && res.data.result) {
          console.log("res", res.data.result, res.data.result.session_id);
          // const session_id = res.data.result.session_id; //cookieValue;//
          // localStorage.setItem("session_id", session_id);
          localStorage.setItem(
            "login_payload",
            JSON.stringify(res.data.result)
          );
          localStorage.setItem(
            "user_context",
            JSON.stringify(res.data.result.user_context)
          );

          // this._vm.$http
          //   .post("/web/session/get_session_info", req_data11, {
          //     withCredentials: true,
          //   })
          //   .then((res_session) => {
          //     console.log("res get session  info:", res_session);
          //   });
          context.commit("auth_success", {
            user_context: res.data.result.user_context,
            response: res.data.result,
          });
          return res;

          // localStorage.setItem("token", session_id);
          // this._vm.$http.defaults.headers.common["X-Openerp"] = res.data.result.session_id;
          // this._vm.$http.defaults.headers.common["db"] = res.data.result.db;
          // this._vm.$http
          //   .post("/web/session/get_session_info", req_data11, {
          //     withCredentials: true,
          //   })
          //   .then((res) => {
          //     console.log("res get session  info:", res);
          //     const session_id = res.data.result.session_id; //cookieValue;//
          //     localStorage.setItem("session_id", session_id);
          //     localStorage.setItem(
          //       "user_context",
          //       JSON.stringify(res.data.result)
          //     );
          //     localStorage.setItem("token", session_id);
          //   });

          // this._vm.$http.defaults.headers.common["X-Openerp"] = session_id;
        } else {
          context.commit("auth_error");
        }
      })
      .catch((err) => {
        // console.log("1111111111111:::::",err);
        context.commit("auth_error");
        localStorage.removeItem("login_payload");
        localStorage.removeItem("user_context");
        return err;
      });
  },
  // Logged Out
  a_LoggedOut({ commit, state }) {
    commit("logged_out");
  },

  // Session info
  async a_session(context, payload) {
    context.commit("auth_session");
    var req_data = {
      jsonrpc: "2.0",
      params: {},
    };

    await this._vm.$http
      .post("/web/session/get_session_info", req_data, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200 && res.data.result) {
          // console.log("res", "session", res.data.result);
          context.commit("auth_session_success", {
            response: res.data.result,
          });
          localStorage.setItem("session_info", JSON.stringify(res.data.result));
          return res;
          // this._vm.$http.defaults.headers.common["X-Openerp"] = session_id;
        } else {
          context.commit("auth_session_error");
        }
      })
      .catch((err) => {
        // console.log("1111111111111:::::",err);
        localStorage.removeItem("session_info");
        context.commit("auth_session_error");
        return err;
      });
  },

  // User Group
  async a_usergroup(context) {
    context.commit("usergroup");
    const user_context = JSON.parse(localStorage.getItem("user_context"));
    const { uid } = user_context;
    var req_data = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        kwargs: { context: { ...user_context } },
        model: "res.users",
        args: [[uid], ["name", "email", "in_group_21", "in_group_23"]],
        method: "read",
      },
    };

    await this._vm.$http
      .post("/web/dataset/call_kw/read", req_data)
      .then((res) => {
        if (res.status === 200 && res.data.result) {
          // console.log("res", "session", res.data.result);
          context.commit("usergroup_success", {
            response: res.data.result,
          });
        } else {
          context.commit("usergroup_error");
        }
      })
      .catch((err) => {
        // console.log("1111111111111:::::",err);
        localStorage.removeItem("session_info");
        context.commit("auth_session_error");
        return err;
      });
  },
};

const authMutators = {
  set_role(state) {
    state.s_role = null;
  },
  set_role_success(state, role) {
    state.s_role = role;
  },
  // Logged In
  logged_in(state, data) {
    const { payload, role } = data;
    state.s_loggedIn = true;
    state.s_role = role;
  },
  // Logged Out
  logged_out(state, status) {
    state.s_loggedIn = null;
    state.s_role = null;
    Cookies.remove("edu_role");
    Cookies.remove("edu_islogged");
    Cookies.remove("edu_lasttab");
    localStorage.removeItem("user_context");
    localStorage.removeItem("session_info");
    localStorage.removeItem("login_payload");
  },

  // Auth Mutations
  auth_request(state) {
    state.s_status_auth = "loading";
  },
  auth_success(state, payload) {
    state.s_status_auth = "success";
    state.s_user = payload.user_context;
    state.s_authpayload = payload.response;
    // state.user_context = payload.user_context;
  },
  auth_error(state) {
    state.s_status_auth = "error";
  },

  // Auth Session
  auth_session(state) {
    state.s_sessionStatus = null;
  },
  auth_session_success(state, payload) {
    state.s_sessionStatus = "";
    state.s_sessionInfo = payload.response;
  },
  auth_session_error(state) {
    state.s_sessionStatus = "error";
  },
  // User Group
  usergroup(state) {
    state.s_userGroupLoadingStatus = null;
  },
  usergroup_success(state, payload) {
    state.s_userGroupLoadingStatus = "success";
    state.s_userGroup = payload.response;
  },
  usergroup_error(state) {
    state.s_userGroupLoadingStatus = "error";
  },
};

export { authStates, authGetters, authActions, authMutators };
